import React from "react";
import { InstructorStudioClassFragment } from "services/graphql";
import { IS_MOBILE } from "constants/index";
import {
  AddClassToScheduleOption,
  AddClassToPlaylistOption,
  SaveStudioClassOption,
  GoToClassStyleOption,
  GoToClassLevelOption,
  DropdownMenuWrapper,
  ShareStudioClassOption,
  PreviewStudioClassOption,
  MobilePreviewClassOption,
  DropdownLineSeparator,
} from "app/components/ClassComponents/DropdownMenuOptions";

interface Props {
  studioClass: InstructorStudioClassFragment;
  onUserSelectsClass: () => void;
}

export function DropdownMenuOptions({
  studioClass,
  onUserSelectsClass,
}: Props) {
  if (!studioClass) {
    return null;
  }

  const classNumber = studioClass.classId;
  const classId = studioClass.id;

  return (
    <DropdownMenuWrapper>
      {IS_MOBILE ? (
        <MobilePreviewClassOption classData={studioClass} />
      ) : (
        <PreviewStudioClassOption
          studioClass={studioClass}
          onUserSelectsClass={onUserSelectsClass}
        />
      )}
      <SaveStudioClassOption studioClass={studioClass} />
      <AddClassToPlaylistOption classNumber={classNumber} classId={classId} />
      <AddClassToScheduleOption classNumber={classNumber} />
      <ShareStudioClassOption studioClass={studioClass} />
      <DropdownLineSeparator />
      <GoToClassStyleOption styleName={studioClass.styleV2?.name} />
      <GoToClassLevelOption classLevel={studioClass.level} />
    </DropdownMenuWrapper>
  );
}
