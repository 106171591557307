import React from "react";
import { H2, P1 } from "app/components/Typography";
import styles from "./styles.module.scss";

interface ContentEmptyStateProps {
  emptyStateTitle?: string;
  emptyStateText?: string;
  EmptyStateTitleComponent?: React.ComponentType;
  EmptyStateTextComponent?: React.ComponentType;
}

export function ContentEmptyState({
  emptyStateTitle,
  emptyStateText,
  EmptyStateTitleComponent,
  EmptyStateTextComponent,
}: ContentEmptyStateProps) {
  const DefaultEmptyStateTitle = () => (
    <H2 className={styles.title}>{emptyStateTitle || "Move your way"}</H2>
  );

  const DefaultEmptyStateText = () => (
    <P1 className={styles.text}>
      {emptyStateText ||
        "Search for styles, levels, instructors, types of classes"}
    </P1>
  );

  const RenderEmptyStateTitle =
    EmptyStateTitleComponent || DefaultEmptyStateTitle;
  const RenderEmptyStateText = EmptyStateTextComponent || DefaultEmptyStateText;

  return (
    <div className={styles.container}>
      <RenderEmptyStateTitle />
      <RenderEmptyStateText />
    </div>
  );
}
