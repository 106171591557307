import React, { useState } from "react";
import Flex from "app/components/Flex";
import Thumbnail from "app/components/Thumbnail";
import { H3 } from "app/components/Typography";
import convertTimeToString from "helpers/convertTimeToString";
import convertTimeObjectToSeconds from "helpers/convertTimeObjectToSeconds";
import { useHistory, useLocation } from "react-router";
import { useContentSelectClassEvent } from "hooks/analytics";
import { getAnalyticsLocationFromPath } from "helpers/analytics";
import { useGetUserIsAnonymous } from "modules/selectors";
import { SignUpModal } from "app/components/SignUpModal";
import { useCanUserTakeClass } from "hooks/Classes";
import { AccessType, InstructorStudioClassFragment } from "services/graphql";
import { ExplicitBadge } from "app/components/ClassCard/Card/components";
import {
  CompletedIcon,
  ThumbnailContainer,
  ThumbnailWrapper,
  ClassInfoText,
  LinkWrapper,
  ProgressBar,
  LockedLabel,
} from "app/routes/Playlists/PlaylistClassRow/styles";
import { ClassInfoLabel } from "app/routes/Playlists/PlaylistClassRow/ClassInfoLabel";
import { DropdownMenuOptions } from "./DropdownMenuOptions";

export function StudioClassRow({
  studioClass,
}: {
  studioClass: InstructorStudioClassFragment;
}) {
  const history = useHistory();
  const location = useLocation();
  const { contentSelectClassEvent } = useContentSelectClassEvent({
    classData: studioClass,
  });
  const isAnonymous = useGetUserIsAnonymous();
  const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false);
  const { canUserTakeClass } = useCanUserTakeClass({
    classId: studioClass.classId,
  });

  const classUrl = `/class/${studioClass.classId}`;

  const goToClass = () => {
    contentSelectClassEvent({
      location: getAnalyticsLocationFromPath(location.pathname),
      selected_from: "Instructor Profile",
    });

    history.push(classUrl, {
      previousLocation: location,
    });
  };

  const handleClick = (e: any) => {
    e.preventDefault();

    if (isAnonymous) {
      setIsSignUpModalOpen(true);
      return;
    }

    goToClass();
  };

  const getUserProgressPercent = () => {
    if (!studioClass.video) {
      return 0;
    }
    const durationInSeconds = Number(studioClass.video.durationInMs) / 1000;
    return (
      (convertTimeObjectToSeconds(studioClass.userProgress?.time) /
        durationInSeconds) *
      100
    );
  };

  return (
    <>
      <Flex width="100%" justifyContent="space-between" maxWidth="768px">
        <Flex gap="16px" flexWrap="wrap">
          <LinkWrapper onClick={handleClick} href={classUrl}>
            <ThumbnailContainer>
              <ThumbnailWrapper>
                <Thumbnail
                  src={`${studioClass.thumbnailImageUrl}&w=600`}
                  placeholderSrc={`${studioClass.thumbnailImageUrl}&blur=200`}
                />
                <Flex
                  position="absolute"
                  top="12px"
                  left="12px"
                  alignItems="center"
                  gap="5px"
                >
                  {!canUserTakeClass &&
                    studioClass.accessType !== AccessType.Free && (
                      <LockedLabel />
                    )}
                  {studioClass.isExplicit && <ExplicitBadge />}
                </Flex>
                {studioClass.userProgress?.completed && <CompletedIcon />}
                {studioClass.userProgress?.percent && (
                  <ProgressBar percent={getUserProgressPercent()} />
                )}
              </ThumbnailWrapper>
            </ThumbnailContainer>
          </LinkWrapper>
          <Flex flexDirection="column">
            <LinkWrapper onClick={handleClick} href={classUrl}>
              <H3 mb="12px" limitLines={2}>
                {studioClass.title}
              </H3>
            </LinkWrapper>
            <Flex gap="8px">
              {studioClass.level && (
                <ClassInfoLabel label={studioClass.level} />
              )}
              {studioClass.video && (
                <ClassInfoLabel
                  label={convertTimeToString(
                    Number(studioClass.video.durationInMs) / 1000,
                    { discrete: true, showSeconds: false }
                  )}
                />
              )}
              <ClassInfoLabel label={studioClass.type} />
            </Flex>
            <Flex gap="8px" alignItems="center">
              <ClassInfoText>{studioClass.styleV2?.name}</ClassInfoText>
              <div> • </div>
              <ClassInfoText>{studioClass.instructor?.name}</ClassInfoText>
            </Flex>
          </Flex>
        </Flex>
        <Flex alignItems="flex-start">
          <DropdownMenuOptions
            studioClass={studioClass}
            onUserSelectsClass={goToClass}
          />
        </Flex>
      </Flex>
      <SignUpModal
        isOpen={isSignUpModalOpen}
        closeModal={() => setIsSignUpModalOpen(false)}
        routeToRedirectAfterClose={location.pathname}
        eventAttributes={{
          class_id: studioClass.classId,
          module: "InstructorProfile",
        }}
      />
    </>
  );
}
