import { useGetInstructorBySlugClassesQuery } from "services/graphql";

export function useGetInstructorClasses({
  instructorSlug,
}: {
  instructorSlug: string;
}) {
  const perPage = 10;

  const {
    data,
    loading,
    error,
    fetchMore,
    refetch,
  } = useGetInstructorBySlugClassesQuery({
    variables: {
      instructorSlug,
      first: perPage,
    },
    notifyOnNetworkStatusChange: true,
  });

  const classes =
    data?.instructorBySlugPublic?.studioClasses?.edges?.map(
      edge => edge?.node
    ) || [];

  const pageInfo = data?.instructorBySlugPublic?.studioClasses?.pageInfo;

  const handleFetchMore = () => {
    if (!pageInfo?.hasNextPage) {
      return;
    }

    fetchMore({
      variables: {
        instructorSlug,
        first: perPage,
        after: pageInfo.endCursor || "",
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }

        return {
          instructorBySlugPublic: {
            ...prev.instructorBySlugPublic,
            studioClasses: {
              ...prev.instructorBySlugPublic.studioClasses,
              edges: [
                ...prev.instructorBySlugPublic.studioClasses.edges,
                ...fetchMoreResult.instructorBySlugPublic.studioClasses.edges,
              ],
              pageInfo:
                fetchMoreResult.instructorBySlugPublic.studioClasses.pageInfo,
            },
          },
        };
      },
    });
  };

  return {
    classes,
    loading,
    error,
    refetch,
    pageInfo,
    handleFetchMore,
    isFetchingInitial: !classes.length && loading,
  };
}
