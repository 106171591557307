import React from "react";
import { P1 } from "app/components/Typography";
import styles from "./styles.module.scss";

interface ErrorScreenProps {
  errorText?: string;
  onPress: () => void;
  pressText?: string;
}

export function ErrorScreen({
  errorText = "There was an error. Please try again.",
  onPress,
  pressText = "Tap to retry",
}: ErrorScreenProps) {
  return (
    <div className={styles.container}>
      <P1 className={styles.errorText}>{errorText}</P1>
      <P1
        className={styles.retryText}
        onClick={onPress}
        role="button"
        tabIndex={0}
        onKeyPress={e => {
          if (e.key === "Enter" || e.key === " ") {
            onPress();
          }
        }}
      >
        {pressText}
      </P1>
    </div>
  );
}
