import React from "react";
import LoaderCentered from "app/components/Loader/LoaderCentered";
import Flex from "app/components/Flex";
import { ContentEmptyState } from "app/components/ContentEmptyState";
import { ErrorScreen } from "app/components/ErrorScreen";
import { Waypoint } from "app/components/Waypoint";
import { StudioClassRow } from "./StudioClassRow";
import { useGetInstructorClasses } from "./hooks";

interface Props {
  instructorSlug: string;
}

export function InstructorClasses({ instructorSlug }: Props) {
  const {
    classes,
    loading,
    error,
    refetch,
    pageInfo,
    handleFetchMore,
    isFetchingInitial,
  } = useGetInstructorClasses({ instructorSlug });

  if (isFetchingInitial) {
    return <LoaderCentered />;
  }

  if (error) {
    return (
      <ErrorScreen
        errorText="Error loading classes. Please try again."
        onPress={() => refetch()}
      />
    );
  }

  if (classes.length === 0) {
    return (
      <ContentEmptyState
        emptyStateTitle="No classes found"
        emptyStateText="Check back later for new classes."
      />
    );
  }

  return (
    <Flex flexDirection="column" gap="24px">
      {classes.map(studioClass => (
        <StudioClassRow key={studioClass?.id} studioClass={studioClass} />
      ))}
      {!loading && (
        <Waypoint hasMore={pageInfo?.hasNextPage} fetchData={handleFetchMore} />
      )}
    </Flex>
  );
}
